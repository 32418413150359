<template>
<div>

    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5">  Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LAS INSPECCIONES:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class="my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-search fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaGestionesSeguimiento.length}}</span>
                            <br>
                            <span class="text-muted">Inspecciones registradas</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de inspecciones</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col v-if="checkPermissions('017-37001-AFC','c') == 1" cols="6" class="my-2">
                            <b-button block variant="custom" @click="modalNuevaInspeccion = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nueva inspección</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5">Inspecciones registradas</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaGestionesSeguimiento" :fields="cabecera" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(internoExterno)="param">
                                    <span v-if="param.item.internoExterno==1">Interno</span>
                                    <span v-else-if="param.item.internoExterno==2">Externo</span>
                                </template>
                                <template #cell(opciones)="param">
                                    <b-button v-if="checkPermissions('017-37001-AFC','u') == 1" @click="abrirModalActualizarInspeccion(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if="checkPermissions('017-37001-AFC','d') == 1" @click="eliminarInspeccion(param)" size="sm" class="mr-1 mb-1" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                                <template v-slot:cell(revisado)="param">
                                    <span v-if="param.item.revisado==2">SI</span>
                                    <span v-if="param.item.revisado==1">NO</span>
                                </template>
                                <template v-slot:cell(aprobado)="param">
                                    <span v-if="param.item.aprobado==2">SI</span>
                                    <span v-if="param.item.aprobado==1">NO</span>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal :closeOnBackdrop="false" size="lg" color="primario" :show.sync="modalNuevaInspeccion">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nueva inspección</span>
            </h6>
            <CButtonClose @click="modalNuevaInspeccion = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(nuevaInspeccion)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="nombre de la publicación" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre de la publicación:" class="mb-2">
                                <b-form-input v-model="datosInspeccion.nombrePublicacion" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre de la publicación"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="versión" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Versión:" class="mb-2">
                                <b-form-input v-model="datosInspeccion.version" :state="getValidationState(validationContext)" placeholder="Ingrese la versión"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="revisado" >
                            <b-form-group label="Revisado:" class="mb-2">
                                <b-form-radio-group plain v-model="datosInspeccion.revisado" :options="[{value:2,text:'SI'},{value:1,text:'NO'}]"></b-form-radio-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="aprobado">
                            <b-form-group label="Aprobado:" class="mb-2">
                                <b-form-radio-group plain v-model="datosInspeccion.aprobado" :options="[{value:2,text:'SI'},{value:1,text:'NO'}]"></b-form-radio-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="responsable" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Responsable:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el responsable" v-model="datosInspeccion.responsable"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fecha de inspección" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de inspección:" class="mb-2">
                                <b-form-input type="date" v-model="datosInspeccion.fechaInspeccion" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="quien - función de cumplimiento de órgano de gobierno" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Quien - función de cumplimiento de órgano de gobierno:" class="mb-2">
                                <b-form-input v-model="datosInspeccion.quien" :state="getValidationState(validationContext)" placeholder="Escriba aquí"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="próxima fecha" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Próxima fecha:" class="mb-2">
                                <b-form-input type="date" v-model="datosInspeccion.proximaFecha" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="documento" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Documento:" class="mb-2">
                                <b-form-file ref="file" class="text-left" v-model="datosInspeccion.documento" v-on:change="handleFileUpload" :state="getValidationState(validationContext)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Elija un archivo o arrástrelo aquí..." browse-text="Subir"></b-form-file>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalNuevaInspeccion = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :closeOnBackdrop="false" size="lg" color="primario" :show.sync="modalActualizarInspeccion">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Actualizar inspección</span>
            </h6>
            <CButtonClose @click="modalActualizarInspeccion = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarInspeccion)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="nombre de la publicación" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre de la publicación:" class="mb-2">
                                <b-form-input v-model="datosInspeccionActualizar.nombrePublicacion" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre de la publicación"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="versión" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Versión:" class="mb-2">
                                <b-form-input v-model="datosInspeccionActualizar.version" :state="getValidationState(validationContext)" placeholder="Ingrese la versión"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="revisado" >
                            <b-form-group label="Revisado:" class="mb-2">
                                <b-form-radio-group plain v-model="datosInspeccionActualizar.revisado" :options="[{value:2,text:'SI'},{value:1,text:'NO'}]"></b-form-radio-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="aprobado">
                            <b-form-group label="Aprobado:" class="mb-2">
                                <b-form-radio-group plain v-model="datosInspeccionActualizar.aprobado" :options="[{value:2,text:'SI'},{value:1,text:'NO'}]"></b-form-radio-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="responsable" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Responsable:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el responsable" v-model="datosInspeccionActualizar.responsable"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fecha de inspección" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de inspección:" class="mb-2">
                                <b-form-input type="date" v-model="datosInspeccionActualizar.fechaInspeccion" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="quien - función de cumplimiento de órgano de gobierno" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Quien - función de cumplimiento de órgano de gobierno:" class="mb-2">
                                <b-form-input v-model="datosInspeccionActualizar.quien" :state="getValidationState(validationContext)" placeholder="Escriba aquí"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="próxima fecha" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Próxima fecha:" class="mb-2">
                                <b-form-input type="date" v-model="datosInspeccionActualizar.proximaFecha" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="documento" v-slot="validationContext">
                            <b-form-group label="Documento:" class="mb-2">
                                <b-input-group>
                                    <b-input-group-prepend v-if="datosInspeccionActualizar.urlDocumento != ''">
                                        <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosInspeccionActualizar.urlDocumento)" v-c-tooltip="'Descargar'">
                                            <i class="fas fa-download fa-xs"></i>
                                        </b-button>
                                    </b-input-group-prepend>
                                    <b-form-file ref="fileActualizar" class="text-left" v-model="datosInspeccionActualizar.documento" v-on:change="handleFileUploadActualizar" :state="getValidationState(validationContext)" :placeholder="datosInspeccionActualizar.nombreDocumento ? datosInspeccionActualizar.nombreDocumento : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="datosInspeccionActualizar.nombreDocumento ? datosInspeccionActualizar.nombreDocumento : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarInspeccion = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {
            modalNuevaInspeccion: false,
            modalActualizarInspeccion: false,

            totalRows: 0,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            cabecera: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "nombrePublicacion",
                    label: "Nombre de publicación",
                    class: "text-center",
                },
                {
                    key: "version",
                    label: "Versión",
                    class: "text-center",
                },
                {
                    key: "revisado",
                    label: "Revisado",
                    class: "text-center",
                },
                {
                    key: "aprobado",
                    label: "Aprobado",
                    class: "text-center",
                },
                {
                    key: "responsable",
                    label: "Responsable",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            campoPersonas: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "control",
                    label: "Control",
                    class: "text-center",
                },
                {
                    key: "acciones",
                    label: '',
                    class: "text-center"
                }
            ],
            listaGestionesSeguimiento: [],
            datosInspeccion: {
                idCliente: '',
                nombrePublicacion: '',
                version: '',
                revisado: 2,
                aprobado: 2,
                responsable: '',
                fechaInspeccion: moment().format('YYYY-MM-DD'),
                quien: '',
                proximaFecha: moment().format('YYYY-MM-DD'),
                documento: null,
                ejercicio: JSON.parse(localStorage.settings).yExcs,
            },
            datosInspeccionActualizar: {
                idInspeccion: '',
                idCliente: '',
                nombrePublicacion: '',
                version: '',
                revisado: '',
                aprobado: '',
                responsable: '',
                fechaInspeccion: '',
                quien: '',
                proximaFecha: '',
                documento: null,
                urlDocumento: '',
                nombreDocumento: '',
                ejercicio: '',
            },
            disabled: false,
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file"].reset();
                    return;
                }

                //this.listaOrganigramas.documento = this.$refs.file.files[0];
            }
        },
        handleFileUploadActualizar(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["fileActualizar"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["fileActualizar"].reset();
                    return;
                }

                //this.listaOrganigramas.documento = this.$refs.file.files[0];
            }
        },
        listarInspecciones() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-inspecciones-sga", {
                        params: {
                            idCliente: me.datosInspeccion.idCliente,
                            ejercicio: JSON.parse(localStorage.settings).yExcs,
                        },
                    }
                )
                .then(function (response) {
                    me.listaGestionesSeguimiento = response.data
                    me.totalRows = me.listaGestionesSeguimiento.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        nuevaInspeccion() {
            let me = this;
            me.disabled = true;
            const formData = new FormData();
            formData.append("file", me.datosInspeccion.documento);
            formData.append("folder", 'sga/actas-funcion-de-cumplimiento');
            formData.append("datosInspeccion", JSON.stringify(me.datosInspeccion));
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-inspeccion-sga",
                    formData, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarInspecciones();
                    me.modalNuevaInspeccion = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },
        actualizarInspeccion() {
            let me = this;
            me.disabled = true;
            const formData = new FormData();
            formData.append("file", me.datosInspeccionActualizar.documento);
            formData.append("folder", 'sga/actas-funcion-de-cumplimiento');
            formData.append("datosInspeccion", JSON.stringify(me.datosInspeccionActualizar));
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-inspeccion-sga",
                    formData, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarInspecciones();
                    me.modalActualizarInspeccion = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },
        abrirModalActualizarInspeccion(param) {
            let me = this;

            me.datosInspeccionActualizar.idInspeccion = param.item.idInspeccion;
            me.datosInspeccionActualizar.idCliente = param.item.idCliente;
            me.datosInspeccionActualizar.nombrePublicacion = param.item.nombrePublicacion;
            me.datosInspeccionActualizar.version = param.item.version;
            me.datosInspeccionActualizar.aprobado = param.item.aprobado;
            me.datosInspeccionActualizar.revisado = param.item.revisado;
            me.datosInspeccionActualizar.responsable = param.item.responsable;
            me.datosInspeccionActualizar.fechaInspeccion = param.item.fechaInspeccion;
            me.datosInspeccionActualizar.quien = param.item.quien;
            me.datosInspeccionActualizar.proximaFecha = param.item.proximaFecha;
            me.datosInspeccionActualizar.nombreDocumento = param.item.nombreDocumento;
            me.datosInspeccionActualizar.urlDocumento = param.item.urlDocumento;
            me.datosInspeccionActualizar.ejercicio = param.item.ejercicio;

            me.modalActualizarInspeccion = true;
        },
        resetModalNuevaInspeccion() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosInspeccion.nombrePublicacion = '';
            this.datosInspeccion.version = '';
            this.datosInspeccion.revisado = 2;
            this.datosInspeccion.aprobado = 2;
            this.datosInspeccion.responsable = '';
            this.datosInspeccion.fechaInspeccion = moment().format('YYYY-MM-DD');
            this.datosInspeccion.quien = '';
            this.datosInspeccion.proximaFecha = moment().format('YYYY-MM-DD');
        },
        resetModalActualizarInspeccion() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
            this.datosInspeccionActualizar.documento = null;

        },
        eliminarInspeccion(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar la inspección?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-inspeccion-sga", {
                            idInspeccion: param.item.idInspeccion,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarInspecciones();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedForm2() {
            return this.$refs.observer2;
        },
    },
    watch: {
        modalNuevaInspeccion: function (val) {
            if (val == false) {
                this.resetModalNuevaInspeccion();
            }
        },
        modalActualizarInspeccion: function (val) {
            if (val == false) {
                this.resetModalActualizarInspeccion();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosInspeccion.idCliente = user.uidClient;
            this.listarInspecciones();
        }

    }

}
</script>
